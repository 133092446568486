import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PlacesService {

    places: any[];
    currentLatitude: number;
    currentLongitude: number;
    errorMessage: string;
    firstLoad = true;

    private firstLoadSubject = new Subject<boolean>();

    notifyEndOfFirstLoad() {
        this.firstLoad = false;
        this.firstLoadSubject.next(false);
    }

    getEndOfFirstLoadNotification() {
        return this.firstLoadSubject.asObservable();
    }

    constructor(){}
}
