import { Component, OnInit } from '@angular/core';
import { PlacesService } from './places.service';


// Google analytics
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'mini-miam';

// Google analytics
// constructor(private placesService: PlacesService) {}

constructor(private placesService: PlacesService, private router: Router) {
  this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe((event: NavigationEnd) => {
     gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
     })
  })

}
  ngOnInit(){
    // console.log('app is loaded for the first time ever');
    setTimeout(() => {
    // console.log('calling service');
      this.placesService.notifyEndOfFirstLoad();
    }, 1800);
  }
}
