import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NearByComponent } from './near-by/near-by.component';
import { PlaceCardComponent } from './place-card/place-card.component';

const routes = [
  { path: '', redirectTo: '/nearby', pathMatch: 'full' },
  { path: 'nearby', component: NearByComponent },
  { path: 'place/:index', component: PlaceCardComponent }
];
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
