import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { PlacesService } from '../places.service';

const DAYS_OF_WEEK = ['lundi:', ',mardi:', ',mercredi:', ',jeudi:', ',vendredi:', ',samedi:', ',dimanche:'];

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.css']
})
export class PlaceCardComponent implements OnInit {

  index: number;
  place: any;
  placeUrl: string;
  horairesByDays: string[];
  hasAvis: boolean = false;
  constructor(private route: ActivatedRoute, private placesService: PlacesService, protected router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params) => this.index = +params['index']);
    this.place = this.placesService.places[this.index];
    this.placeUrl = 'https://www.google.com/maps/search/?api=1&query=' + this.place.adresse
        + '&query_place_id=' + this.place.place_id;
    this.getHoraires(this.place);
    if (this.place.hasAvis) {
      this.hasAvis = true;
    }
  }

  goBack() {
    this.router.navigate(['/nearby']);
  }

  getHoraires(place: any) {
    let horairesWithSlash = place.horaires;
    for (let i = 0; i <= DAYS_OF_WEEK.length; i++) {
      if (i === 0) {
        horairesWithSlash = horairesWithSlash.replace(DAYS_OF_WEEK[i], '');

      } else {
        horairesWithSlash = horairesWithSlash.replace(DAYS_OF_WEEK[i], '/');
      }
    }
    this.horairesByDays = horairesWithSlash.split('/');

  }

  showNearbyList(place: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        latitude: place.coo_lat,
        longitude: place.coo_long,
        situation: place.nom_etablissement
      }
    }
    this.router.navigate(['/nearby'], navigationExtras);
  }

  getShortAdress(originalAdress: string): string {
    let shortAdress = '';
    let adressParts = originalAdress.split(',');
    for (let i = 0; i < adressParts.length - 1; i++) {
      shortAdress += adressParts[i];
    }
    return shortAdress;
  }

  getTelephoneLink(telephone: string): string {
    return 'tel:' + telephone;
  }

}
