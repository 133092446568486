import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PlacesService } from '../places.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

const DAYS_OF_WEEK = ['lundi:', ',mardi:', ',mercredi:', ',jeudi:', ',vendredi:', ',samedi:', ',dimanche:'];
const NO_HOURS = 'NO OPENING HOURS';

@Component({
  selector: 'app-near-by',
  templateUrl: './near-by.component.html',
  styleUrls: ['./near-by.component.css']
})
export class NearByComponent implements OnInit, OnDestroy {

  state: Observable<object>;
  places: any[];
  filteredList: any[];
  currentLatitude: number;
  currentLongitude: number;
  placeLatitude: number;
  placeLongitude: number;
  placeSituation: string;
  errorMessage: string;
  dayOfWeek: number;
  selectedType: string;
  firstLoad: boolean;
  endOfFirstLoadSub: Subscription;
  geoloc_active: boolean;

  constructor(protected router: Router, private route: ActivatedRoute, private http: HttpClient, private placesService: PlacesService) {
    this.firstLoad = this.placesService.firstLoad;
    this.endOfFirstLoadSub = this.placesService.getEndOfFirstLoadNotification().subscribe((firstLoad) => this.firstLoad = firstLoad);
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.placeLatitude = params['latitude'];
      this.placeLongitude = params['longitude'];
      this.placeSituation = params['situation'];
    })
    this.getCurrentPosition();
    this.places = this.route.snapshot.data['places'];
    let now = new Date();
    this.dayOfWeek = now.getDay();
  }

  initializePlaces() {
    this.getJson().subscribe(
        (data: any) => {
            this.places = JSON.parse(data);
            this.placesService.places = this.places;  
            this.getClosestPlaces(this.places);    
        },
        (error) => {
            this.errorMessage = error.message;
        }
    ); 
}

goHome() {
  this.resetList();
}

resetList() {
  if (window.navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(
    (browserPosition) => {
      this.currentLatitude = browserPosition.coords.latitude;
      this.currentLongitude = browserPosition.coords.longitude;
      this.placesService.currentLatitude = browserPosition.coords.latitude;
      this.placesService.currentLongitude = browserPosition.coords.longitude;
      this.initializePlaces();
    },
    (error) => {
        this.errorMessage = error.message;
      }
    );
  }
}

getCurrentPosition() {
  //48.8512956,2.3201004 Coordonnées de TEST
  // this.currentLatitude = 48.8512956;
  // this.currentLongitude = 2.3201004;
  // OR
  this.geoloc_active = true;

   if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
      (browserPosition) => {
        this.currentLatitude = browserPosition.coords.latitude;
        this.currentLongitude = browserPosition.coords.longitude;
        this.placesService.currentLatitude = browserPosition.coords.latitude;
        this.placesService.currentLongitude = browserPosition.coords.longitude;
        if (this.placeLatitude && this.placeLongitude) {
          this.currentLatitude = this.placeLatitude;
          this.currentLongitude = this.placeLongitude;
        }
        this.initializePlaces();
      },
      (error) => {
          this.errorMessage = error.message;
          this.geoloc_active = false;
        }
      );
    }
  }

  getJson(): Observable<any> {
    // ****************** Fichier JSON pour version PROD *********************
    return this.http.get("https://minimiam.fr/assets/export_miam_patissier.json", { responseType: 'text' });
    // Chemin pour dev
    // return this.http.get("./../assets/export_miam_patissier.json", { responseType: 'text' });
  }
  
  getClosestPlaces(places: any[]) {
    for (let i in places) {
      var placeLatitude = places[i].coo_lat;
      var placeLongitude = places[i].coo_long;
      const distance = 6371 * Math.acos(Math.cos(this.toRadian(this.currentLatitude)) * Math.cos(this.toRadian(placeLatitude))  * Math.cos(this.toRadian(placeLongitude)  - this.toRadian(this.currentLongitude)) + Math.sin(this.toRadian(this.currentLatitude)) * Math.sin(this.toRadian(placeLatitude)));

      places[i].distance = Math.round(distance * 10) * 100;
      places[i].distancekm = Math.round(distance * 10)/10;
      places[i].distancekmloin = Math.round(distance );
      
      // places[i].timeToDestination = Math.round(distance * 16);
      if (places[i].horaires && places[i].horaires !== NO_HOURS) {
        places[i].hoursByDay = this.getHoraires(places[i]);
        places[i].isOpened = this.isPlaceOpened(places[i]);
      } else {
        places[i].hoursByDay = NO_HOURS;
      }
      places[i].nbTops = this.getNbTop(places[i]);
    }
    places.sort(function(a, b) {
      if (a.distance < b.distance) {
      return -1;
      } else if (a.distance > b.distance) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getNbTop(place: any): number {
    let topCount = 0;
    place.hasAvis = false;

    if (
          place.fdp_trophees_2021
      ||  place.palmares2020_tribunaldesgateaux
      ||  place.palmares2019_tribunaldesgateaux
      ||  place.best_millefeuilles_2019 === 0 
      ||  place.best_flan_echos2021 === 0
      ||  place.best_croissant_express_2018	=== 0
      ||  place.best_kouign_amanns_Figaro_2018
      ||  place.best_tartecitron_Express_2018 === 0
      || 	place.best_beignet_Express_2018	=== 0
      ||  place.best_fraisier_Express_2019	=== 0
      ||  place.best_macaron_Express_2020	=== 0
      ||  place.best_tartefraise_Express_2018	=== 0
      ||  place.coupsdecoeursucres_Express_2020	=== 0
      ||  place.best_croissant_vogue_2021	=== 0
      ||  place.best_boulangerie_vogue_2020	=== 0
      ||  place.best_patisserie_vogue_2020	=== 0
      ||  place.best_flan_lemonde_2021 === 0
      ||  place.best_tartecitron_lemonde_2021 === 0
      ||  place.best_painchocolat_lemonde_2021 === 0
      ||  place.best_tartefraise_lemonde_2021 === 0
      ||  place.best_eclair_lemonde_2021 === 0
      ||  place.best_cookie_lemonde_2021 === 0
      ||  place.lemonde_quoiquilencroute == 0
      )
    {
      topCount++;
      place.hasAvis = true;
    }

    return topCount;
  }

  getHoraires(place: any): any[] {
    let horairesWithSlash = place.horaires;
    for (let i = 0; i <= DAYS_OF_WEEK.length; i++) {
      if (i === 0) {
        horairesWithSlash = horairesWithSlash.replace(DAYS_OF_WEEK[i], '');

      } else {
        horairesWithSlash = horairesWithSlash.replace(DAYS_OF_WEEK[i], '/');
      }
    }
    let horairesByDaysWrongIndex = horairesWithSlash.split('/');
    let hoursByDay = [];
    for (let j = 0; j <= DAYS_OF_WEEK.length; j++) {
      if (j === 0) {
        hoursByDay[j] = horairesByDaysWrongIndex[6];
      } else {
        hoursByDay[j] = horairesByDaysWrongIndex[j - 1];
      }
      let hoursTableForTheDay = [];
      if (hoursByDay[j].trim() === 'Fermé') {
        hoursByDay[j] = hoursTableForTheDay;
      } else {
        let splittedHours = hoursByDay[j].split(',');
        if (splittedHours.length > 1) {
          hoursTableForTheDay[0] = splittedHours[0];
          hoursTableForTheDay[1] = splittedHours[1];
        } else {
          hoursTableForTheDay[0] = hoursByDay[j];
        }
        hoursByDay[j] = hoursTableForTheDay;
      }
    }
    return hoursByDay;
  }

  isPlaceOpened(place: any): boolean {
    const now = moment.now();
    var etablissementOuvert = false;
    const hoursForTheDay = place.hoursByDay[this.dayOfWeek];
    if (hoursForTheDay) {
      if (hoursForTheDay.length === 0) {
        return false;
      }
      let splittedPlage1 = hoursForTheDay[0].split(' – ');
      etablissementOuvert = moment(splittedPlage1[0], 'hh:mma').isBefore(now) && moment(splittedPlage1[1], 'hh:mma').isAfter(now);
      place.closingTime = etablissementOuvert ? splittedPlage1[1] : null;

      if (hoursForTheDay.length > 1) {
        let splittedPlage2 = hoursForTheDay[1].split(' – ');
        if (moment(splittedPlage1[0], 'hh:mma').isBefore(now) && moment(splittedPlage1[1], 'hh:mma').isAfter(now)) {
          etablissementOuvert = true;
          place.closingTime = splittedPlage1[1];
        }
        if (moment(splittedPlage2[0], 'hh:mma').isBefore(now) && moment(splittedPlage2[1], 'hh:mma').isAfter(now)) {
          etablissementOuvert = true;
          place.closingTime = splittedPlage2[1];
        }
      }
    }
    return etablissementOuvert;
  }
  
  toRadian(degrees: number): number {
    return (Math.PI * degrees) / 180;
  }

  filterListAccordingToType(type: string) {
    this.filteredList = this.selectedType === type ? null : this.places.filter(place => place[type] === true);
    this.selectedType = this.selectedType === type ? null : type;
    this.placesService.places = this.selectedType === null ?
      this.placesService.places = this.places : this.placesService.places = this.filteredList;
  }

  getShortAdress(originalAdress: string): string {
    let shortAdress = '';
    let adressParts = originalAdress.split(',');
    for (let i = 0; i < adressParts.length - 1; i++) {
      shortAdress += adressParts[i];
    }
    return shortAdress;
  }

  ngOnDestroy() {
    this.endOfFirstLoadSub.unsubscribe();
  }
 }
